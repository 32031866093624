export const ROUTES = {
    INDEX: '/',
    HOME: '/home',
    UNAUTHORISED: '/unauthorised',
    NOT_FOUND: '/not-found',

    AUTH: {
        LOGIN: '/login',
        LOGOUT: '/logout',
        SIGN_UP: '/sign-up',
        VERIFY_OTP: '/verify-otp',
        FORGOT_PASSWORD: '/forgot-password',
        VERIFY_PASSWORD_OTP: '/verify-password-change-otp',
    },

    LOGGED_IN: {
        SETTINGS: '/account-settings'
    },

    SYSTEM_ADMIN: {
        DASHBOARD: '/sysadmin',
    },

    DIVISION_HEAD: {
        DASHBOARD: '/admin',
    },

    GHAT_IC: {
        DASHBOARD: '/admin',
        GHAT: '/ghats',
        ROUTE: '/routes',
        TRIP: '/trips',
        VESSEL: '/vessels',
    },

    GHAT_OPERATOR: {
        DASHBOARD: '/ghat-operator',
        TRIP_MANAGE: '/ghat-operator/trip-manage',
        ROUTE: '/routes',
        TRIP: '/trips',
        VESSEL: '/vessels',
    },

    MANAGE: {
        DIVISION: '/divisions',
        GHAT: '/ghats',
        ROUTE: '/routes',
        TRIP: '/trips',
        VESSEL: '/vessels',
        VESSEL_LAYOUT: '/vessel-layout',
        RATE: '/rate',
        TRIP_SETTINGS: '/trip-settings',
        USERS: '/user-management',
    },

    USER: {
        DASHBOARD: '/user',
        SEARCH_RESULTS: '/search-results',
        TICKET_BOOKING: '/book-tickets',
        MY_BOOKINGS: '/my-bookings',
        BOOKING_TICKET_DETAILS: '/booking-ticket-details',
        BUNDLE_TICKET_BOOK: '/book-bundle',
        ORDER_DETAILS: '/order-details'
    }
}