import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import DefaultButton from '../../../../components/buttons/DefaultButton';
import FormControl from '../../../../components/forms/FormControl';
import ModalWrapper from '../../../../components/modals/ModalWrapper';
import { SelectOption } from '../../../../constants/type-constants';
import { useCommonService } from '../../../../hooks/useCommonService';
import { validationService } from '../../../../services/validationService';
import { dateUtil } from '../../../../utility/dateUtil';
import { InitialModalDetails, InitialPassengerState, ModalDetails, Passenger } from '../ticket-booking-interfaces';
import { useFormErrorContext } from '../../../../hooks/useFormErrorContext';
import { useTranslation } from 'react-i18next';

interface PassengerModalProps {
    passengerList: Passenger[]
    setPassengerList: React.Dispatch<React.SetStateAction<Passenger[]>>
    modalDetails: ModalDetails
    setModalDetails: React.Dispatch<React.SetStateAction<ModalDetails>>
    availability: number
}

const PassengerModal: React.FC<PassengerModalProps> = ({ ...props }) => {

    const { t } = useTranslation();
    const { fetchGenders, fetchPwd } = useCommonService();
    const { setFormErrors } = useFormErrorContext();

    const [genderOptions, setGenderOptions] = useState<SelectOption[]>([]);
    const [pwdOptions, setPwdOptions] = useState<SelectOption[]>([]);

    const [passengerModalOpen, setPassengerModalOpen] = useState(false);
    const handleModalOpen = () => {
        if (props.availability === 0) {
            toast.error("No seat available to book.");
            return;
        }
        setPassengerModalOpen(true);
    }
    const handleModalClose = () => {
        props.setModalDetails(InitialModalDetails);
        setPassengerModalOpen(false)
    };

    const [passengerForm, setPassengerForm] = useState<Passenger>(InitialPassengerState);

    useEffect(() => {
        fetchGenders(setGenderOptions);
        fetchPwd(setPwdOptions);
    }, []);

    useEffect(() => {
        if (props.modalDetails.itemType === 'seat') {
            if (props.modalDetails.action === 'add') {
                const id = dateUtil.getTimestamp().toString();
                setPassengerForm({
                    id: id,
                    passengerName: '',
                    email: '',
                    mobileNo: '',
                    age: 0,
                    gender: genderOptions[0]?.value,
                    pwd: pwdOptions[0]?.value,
                    uniqueId: id
                })
            } else if (props.modalDetails.action === 'edit') {
                const passenger = props.passengerList.find(passenger => passenger.id === props.modalDetails.id);
                if (passenger) {
                    setPassengerForm(passenger);
                } else {
                    toast.error("Passenger not found!");
                }
            }
            handleModalOpen();
        }
    }, [props.modalDetails])

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { value, name } = e.target;

        if (name === 'mobileNo' && !validationService.isValidMobileInput(value)) {
            return;
        }
        setPassengerForm((prevForm) => ({
            ...prevForm,
            [name]: value
        }));
    }

    const handleSaveClick = () => {
        const errorDetails: {
            passengerName?: string;
            age?: string;
            gender?: string;
            pwd?: string;
        } = {};

        if (passengerForm.passengerName === '') {
            errorDetails.passengerName = "Please enter name";
        }
        if (!passengerForm.age || passengerForm.age < 1) {
            errorDetails.age = "Please enter age";
        }
        if (passengerForm.gender === '') {
            errorDetails.gender = "Please select gender";
        }

        if (Object.keys(errorDetails).length > 0) {
            setFormErrors(errorDetails);
            return;
        }
        
        const passengerIndex = props.passengerList.findIndex(p => p.id == passengerForm.id);
        if (passengerIndex != -1) {
            const updatedPassengerList = [...props.passengerList];
            updatedPassengerList[passengerIndex] = passengerForm;
            props.setPassengerList(updatedPassengerList);
            toast.success('Passenger Details Updated');
        } else {
            props.setPassengerList([...props.passengerList, passengerForm]);
            toast.success('Passenger Added');
        }
        handleModalClose();
    }

    return (
        <ModalWrapper
            title={t('passenger_details')}
            open={passengerModalOpen}
            handleClose={handleModalClose} >
            <div id="passengerForm" className='flex flex-wrap w-full'>
                <div className='w-full mb-3 lg:w-2/4 lg:pr-4'>
                    <input type="text" value={passengerForm.id} readOnly hidden />
                    <FormControl
                        label={t('name')}
                        type={'text'}
                        id={'passengerName'}
                        value={passengerForm.passengerName}
                        onChange={handleInputChange}
                        mandatoryField={true}
                    />
                </div>
                <div className='w-full mb-3 lg:w-1/4 lg:pr-3'>
                    <FormControl
                        label={t('age')}
                        type={'number'}
                        id={'age'}
                        value={passengerForm.age}
                        onChange={handleInputChange}
                        mandatoryField={true}
                    />
                </div>
                <div className='w-full mb-3 lg:w-1/4'>
                    <FormControl
                        type={'select'}
                        id={'gender'}
                        label={t('gender')}
                        options={genderOptions}
                        value={passengerForm.gender}
                        onChange={handleInputChange}
                        mandatoryField={true}
                    />
                </div>
                <div className='w-full mb-3 lg:w-1/3 lg:pr-3'>
                    <FormControl
                        label={t('phone')}
                        type={'text'}
                        id={'mobileNo'}
                        value={passengerForm.mobileNo}
                        onChange={handleInputChange}
                    />
                </div>
                <div className='w-full mb-3 lg:w-1/3 lg:pr-3'>
                    <FormControl
                        label={t('email')}
                        type={'email'}
                        id={'email'}
                        value={passengerForm.email}
                        onChange={handleInputChange}
                    />
                </div>
                <div className='w-full mb-3 lg:w-1/3'>
                    <FormControl
                        type={'select'}
                        id={'pwd'}
                        label={t('differently_abled')}
                        options={pwdOptions}
                        value={passengerForm.pwd}
                        onChange={handleInputChange}
                        mandatoryField={true}
                    />
                </div>
                <div className='flex justify-center w-full mt-3'>
                    <DefaultButton
                        onClick={handleSaveClick}
                        buttonText={t('save_passenger_details')}
                    />
                </div>
            </div>
        </ModalWrapper>
    )
}

export default PassengerModal;