import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import toast from 'react-hot-toast'
import Body from '../../components/body/Body'
import DefaultButton from '../../components/buttons/DefaultButton'
import Card from '../../components/cards/Card'
import FormControl from '../../components/forms/FormControl'
import API_ENDPOINT from '../../constants/api-constants'
import { ROUTES } from '../../constants/routes-constants'
import { useApiService } from '../../hooks/useApiService'
import { useHandleErrorService } from '../../hooks/useHandleErrorService'
import { useTranslation } from 'react-i18next'

const SignUp: React.FC = () => {

    const { t } = useTranslation();
    const { postPublicRequest } = useApiService();
    const { handleError } = useHandleErrorService();

    const navigate = useNavigate();
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [mobileNo, setMobileNo] = useState('');

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch (e.target.name) {
            case 'fullName':
                setFullName(e.target.value);
                break;
            case 'email':
                setEmail(e.target.value);
                break;
            case 'mobileNo':
                setMobileNo(e.target.value);
                break;
            default:
                break;
        }
    }

    const handleClick = async () => {
        const postData = {
            fullName: fullName,
            mobileNo: mobileNo,
            email: email,
        }

        try {
            const response: any = await postPublicRequest(API_ENDPOINT.AUTH.POST_SEND_OTP, postData, true);
            if (response) {
                const { message } = response.data;

                toast.success(message);
                setTimeout(() => {
                    Cookies.set('fullName', fullName, { secure: true });
                    Cookies.set('email', email, { secure: true });
                    Cookies.set('mobileNo', mobileNo, { secure: true });
                    navigate(ROUTES.AUTH.VERIFY_OTP);
                }, 1500);
            }
        } catch (error: any) {
            handleError(error);
        }
    }

    return (
        <Body>
            <div className="w-full md:w-7/12 lg:w-5/12 flex justify-center m-auto">
                <Card>
                    <div>
                        <h1 className="text-sky-600 text-xl font-medium text-center leading-tight tracking-tight md:text-2xl">
                            {t('sign_up')}
                        </h1>
                        <div className="space-y-2 md:space-y-3" id="signUpForm">
                            <FormControl
                                label={t('name')}
                                type={'text'}
                                id={'fullName'}
                                value={fullName}
                                onChange={handleInputChange}
                                mandatoryField={true}
                            />
                            <FormControl
                                label={t('phone')}
                                type={'text'}
                                id={'mobileNo'}
                                value={mobileNo}
                                onChange={handleInputChange}
                                mandatoryField={true}
                            />
                            <FormControl
                                label={t('email')}
                                type={'email'}
                                id={'email'}
                                value={email}
                                onChange={handleInputChange}
                                mandatoryField={false}
                            />
                            <br />
                            <DefaultButton
                                onClick={handleClick}
                                buttonText={t('get_otp')}
                            />

                            <p className="text-sm font-medium text-gray-500">
                                {t('already_registered')} <button onClick={() => navigate(ROUTES.AUTH.LOGIN)} className="font-semibold text-sky-600 hover:underline">{t('login')}</button>
                            </p>
                        </div>
                    </div>
                </Card>
            </div>

        </Body >
    )
}

export default SignUp