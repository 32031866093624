import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import QRCode from 'qrcode';
import { dateUtil } from '../../../../utility/dateUtil';
import { BookingTicketTypes, GoodsView, PassengerView, VehicleView } from '../../payment/order-details-interfaces';
import { IBookingTicketDetails } from './booking-ticket-details-interfaces';

export default function useTicketGenerationService(t: (key: string) => string) {

    const drawSeparator = (doc: jsPDF, y: number) => {
        doc.text('---------------------------------------', 10, y);
    };

    const drawTable = (doc: jsPDF, data: string[][], startY: number) => {
        autoTable(doc, {
            body: data,
            startY,
            styles: {
                font: 'courier',
                fontSize: 12,
                fontStyle: 'bold',
                halign: 'left',
                valign: 'top',
                cellPadding: 1,
                textColor: '#000000',
            },
            columnStyles: {
                0: { halign: 'left' },
                1: { halign: 'right' },
            },
            alternateRowStyles: { fillColor: [255, 255, 255] }
        });
    };

    const generateTicketPdf = async (bookingTicketDetails: IBookingTicketDetails) => {
        const doc = new jsPDF({
            unit: 'mm',
            format: [120, 240],
        });

        // Register the custom font
        doc.setFont("Courier", "bold");
        doc.setFontSize(12);

        const generateTicketPage = async (
            type: BookingTicketTypes,
            item: PassengerView['item'] | VehicleView['item'] | GoodsView['item'],
            passenger: PassengerView | null,
            vehicle: VehicleView | null,
            goods: GoodsView | null,
            ticketNo: string,
            index: number,
            rate: string,
        ) => {
            if (index > 0) doc.addPage();

            drawSeparator(doc, 10);
            doc.text(t('ticket_org_name_1'), 60, 15, { align: 'center' });
            doc.text(t('ticket_org_name_2'), 60, 20, { align: 'center' });
            drawSeparator(doc, 25);

            const bookingDetails = [
                [t('pnr_no') + ":", bookingTicketDetails.pnrNo],
                [t('booking_no') + ":", bookingTicketDetails.bookingNo],
                [t('booking_date') + ":", dateUtil.formatDateTime(bookingTicketDetails.confirmationDate)],
            ];
            bookingDetails.forEach(([label, value], idx) =>
                doc.text(`${label}: ${value}`, 60, 30 + idx * 5, { align: 'center' })
            );

            drawSeparator(doc, 45);
            // doc.text(`Item: ${item.itemCategory} (${item.itemType})`, 10, 50);
            doc.text(`${t('item_category')}: ${item.itemCategory}`, 10, 50);
            drawSeparator(doc, 55);

            doc.text(`${t('trip_details')}:`, 10, 60);

            // change code after multilingual setup. following is the original code
            // drawTable(doc, [
            //     ["Route", `${bookingTicketDetails.trip.route.ghatFrom.ghatName} --> ${bookingTicketDetails.trip.route.ghatTo.ghatName}`],
            //     ["Departure", `${dateUtil.formatDateTime(bookingTicketDetails.trip.tripDate).split('|')[0].trim()}, ${bookingTicketDetails.trip.departureTime}`],
            //     ["Duration", bookingTicketDetails.trip.tripDuration]
            // ], 62);

            drawTable(doc, [
                [t('route'), `${bookingTicketDetails.trip.route.ghatFrom.ghatName.split('(')[0]} --> ${bookingTicketDetails.trip.route.ghatTo.ghatName.split('(')[0]}`],
                [t('departure_time'), `${dateUtil.formatDateTime(bookingTicketDetails.trip.tripDate).split('|')[0].trim()}, ${bookingTicketDetails.trip.departureTime}`],
                [t('duration'), bookingTicketDetails.trip.tripDuration]
            ], 62);

            drawSeparator(doc, 90);
            doc.text(`${t('vessel_details')}:`, 10, 95);
            drawTable(doc, [
                [t('name'), `${bookingTicketDetails.trip.vessel.name} (${bookingTicketDetails.trip.vessel.vesselType})`],
                [t('reg_no'), `${bookingTicketDetails.trip.vessel.regNo}`],
                [t('dock_no'), `${bookingTicketDetails.trip.dockNo}`]
            ], 97);

            drawSeparator(doc, 125);
            doc.text(t('passenger_details'), 10, 130);

            const ticketDetailsTableData = {
                passengers: [
                    [t('name'), `${passenger?.passengerName}`],
                    [t('gender') + '/' + t('age') + '/' + t('differently_abled'), `${passenger?.gender}/${passenger?.age}/${passenger?.pwd}`],
                    [t('ticket_no'), `${passenger?.ticketNo}`],
                ],
                vehicles: [
                    [t('reg_no'), `${vehicle?.regNo} (${vehicle?.model})`],
                    [t('owner'), `${vehicle?.ownerName}`],
                    [t('ticket_no'), `${vehicle?.ticketNo}`],
                ],
                goods: [
                    [t('description'), `${goods?.description}`],
                    [t('quantity'), `${goods?.weight}`],
                    [t('ticket_no'), `${goods?.ticketNo}`],
                ],
            };

            drawTable(doc, ticketDetailsTableData[type], 132);

            drawSeparator(doc, 160);
            doc.text(`${t('fare')}: ${rate}`, 60, 165, { align: 'center' });

            const qrCodeData = await QRCode.toDataURL(JSON.stringify({
                bookingNo: bookingTicketDetails.bookingNo,
                ticketPnr: ticketNo,
                tripId: bookingTicketDetails.trip.id,
                itemCategory: item.itemCategory
            }));

            doc.addImage(qrCodeData, 'PNG', 35, 170, 50, 50);
        };

        const generateTickets = async () => {

            for (let i = 0; i < bookingTicketDetails.passengers.length; i++) {
                await generateTicketPage(
                    'passengers',
                    bookingTicketDetails.passengers[i].item,
                    bookingTicketDetails.passengers[i],
                    null,
                    null,
                    bookingTicketDetails.passengers[i].ticketNo,
                    i,
                    bookingTicketDetails.passengers[i].rate
                );
            }

            for (let i = 0; i < bookingTicketDetails.vehicles.length; i++) {
                await generateTicketPage(
                    'vehicles',
                    bookingTicketDetails.vehicles[i].item,
                    null,
                    bookingTicketDetails.vehicles[i],
                    null,
                    bookingTicketDetails.vehicles[i].ticketNo,
                    i + bookingTicketDetails.passengers.length,
                    bookingTicketDetails.vehicles[i].rate
                );
            }

            for (let i = 0; i < bookingTicketDetails.goods.length; i++) {
                await generateTicketPage(
                    'goods',
                    bookingTicketDetails.goods[i].item,
                    null,
                    null,
                    bookingTicketDetails.goods[i],
                    bookingTicketDetails.goods[i].ticketNo,
                    i + bookingTicketDetails.passengers.length + bookingTicketDetails.vehicles.length,
                    bookingTicketDetails.goods[i].rate);
            }
        };

        await generateTickets();

        // Download the PDF
        doc.save(`Booking_Tickets_${bookingTicketDetails.pnrNo}.pdf`);
    }

    return {
        generateTicketPdf
    }
}
