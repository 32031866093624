import React, { useState } from 'react'
import ModalWrapper from '../../../components/modals/ModalWrapper';
import SearchInput from '../../../components/forms/SearchInput';
import { ISearchParams } from './landing-interfaces';
import { useTranslation } from 'react-i18next';

interface GhatSelectModalProps {
    ghatOptionModalOpen: boolean
    handleGhatOptionModalClose: () => void
    searchInputRef: React.RefObject<HTMLInputElement>
    ghatList: any[]
    searchParams: ISearchParams
    setSearchParams: React.Dispatch<React.SetStateAction<ISearchParams>>
    selectedParameter: string
    setSelectedParameter: React.Dispatch<React.SetStateAction<string>>
}

const GhatSelectModal: React.FC<GhatSelectModalProps> = ({ ...props }) => {

    const { t } = useTranslation();
    const [searchText, setSearchText] = useState('');

    const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };

    const handleSearchOptionSelect = (ghatId: any, ghatName: string, parent: string) => {
        switch (parent) {
            case 'origin':
                if (ghatId !== props.searchParams.origin.originId) {
                    props.setSearchParams({
                        ...props.searchParams,
                        origin: {
                            originId: ghatId,
                            originName: ghatName
                        },
                        destination: {
                            destinationId: '',
                            destinationName: t('destination')
                        }
                    });
                }
                break;
            case 'destination':
                if (ghatId != props.searchParams.destination.destinationId) {
                    props.setSearchParams({
                        ...props.searchParams,
                        destination: {
                            destinationId: ghatId,
                            destinationName: ghatName
                        }
                    });
                }
                break;
            default:
                break;
        }

        props.handleGhatOptionModalClose();
        props.setSelectedParameter('');
        setSearchText('');
    }

    const matchesSearchText = (ghatName: string) => {
        if (searchText.trim() !== '') {
            return ghatName.toLowerCase().includes(searchText.toLowerCase());
        }

        return true;
    }

    return (
        <ModalWrapper
            open={props.ghatOptionModalOpen}
            handleClose={props.handleGhatOptionModalClose}
            title={t('select_ghat')}
            width="small"
        >
            <div className="flex flex-col items-center justify-center">
                <SearchInput
                    id={"ghatInput"}
                    onChange={handleSearchInputChange}
                    value={searchText}
                    inputRef={props.searchInputRef}
                />

                <div className="mt-2 w-full lg:h-80 lg:overflow-y-scroll">
                    {
                        props.ghatList.map((ghat => (
                            <div key={ghat.divisionId}>
                                <div className="my-1 w-full px-4 py-1 text-sm bg-slate-200">
                                    {ghat.divisionName}
                                </div>
                                {ghat.ghats.map(((ghat: any) => (
                                    (matchesSearchText(ghat.name) &&
                                        <div key={ghat.id}
                                            className="bg-slate-100 border border-1 border-slate-200 my-1 mx-2 px-4 py-2 lg:py-1 text-sm hover:cursor-pointer"
                                            onClick={() => handleSearchOptionSelect(ghat.id, ghat.name, props.selectedParameter)}
                                        >
                                            {ghat.name}
                                        </div>)
                                )))}
                            </div>
                        )))
                    }
                </div>
            </div>
        </ModalWrapper>
    )
}

export default GhatSelectModal;