import React from 'react'
import { dateUtil } from '../../utility/dateUtil'
import { Container } from '@mui/material'

const DefaultFooter: React.FC = () => {
  return (
    <div className='text-slate-300 text-sm leading-6'>
      <div className='bg-slate-600'>
        <Container>
          <div className='grid grid-cols-1 lg:grid-cols-3 py-10'>
            <div>
              <p>About Us</p>
              <p>History</p>
            </div>
            <div className='flex flex-col ld:mx-auto'>
              <p>Privacy Policy</p>
              <p>Terms and Conditions</p>
              <p>Refund / Cancellation Policy</p>
            </div>
            <div className='flex flex-col lg:ml-auto'>
              <p>Contact Us</p>
              <p>Email: example@gmail.com</p>
              <p>Address Line 1</p>
              <p>Address Line 2</p>
              <p>Guwahati - 781001</p>
            </div>
          </div>
        </Container>
      </div>
      <div className='bg-slate-700'>
        <Container>
          <div className='py-4 text-center'>
            <div>
              <p>Copyright © {dateUtil.getCurrentYear()} | <a href='#'
                className="hover:underline"
                target='_blank'
                rel="noopener noreferrer">Assam Inland Water Transport Development Society</a> | All Rights Reserved.</p>
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default DefaultFooter
