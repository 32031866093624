import { Container } from '@mui/material'
import React from 'react'
import Card from '../../../../components/cards/Card'
import { EventAvailable, Lock, NotificationsActive, TouchApp } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

const Features: React.FC = () => {

    const { t } = useTranslation();

    const features = [
        {
            icon: <EventAvailable fontSize='large' />,
            title: t('ease_of_booking'),
            description: t('ease_of_booking_body'),
        },
        {
            icon: <NotificationsActive fontSize='large' />,
            title: t('real_time_updates'),
            description: t('real_time_updates_body'),
        },
        {
            icon: <TouchApp fontSize='large' />,
            title: t('user_friendly_design'),
            description: t('user_friendly_design_body'),
        },
        {
            icon: <Lock fontSize='large' />,
            title: t('secure_transactions'),
            description: t('secure_transactions_body'),
        },
    ]

    return (
        <div className='my-6'>
            <Container>
                {/* Features */}
                <section>
                    <h2 className="text-sky-700 mb-4">{t('features_header')}</h2>
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                        {
                            features.map((feature, index) => (
                                <div key={index} className='h-full'>
                                    <Card>
                                        <div className="flex items-center justify-center text-sky-600">
                                            {feature.icon}
                                        </div>
                                        <div className="text-center mt-4">
                                            <h3 className="text-lg font-semibold text-slate-800">{feature.title}</h3>
                                            <p className="text-slate-600 text-sm">{feature.description}</p>
                                        </div>
                                    </Card>
                                </div>
                            ))
                        }
                    </div>
                </section>
                <hr className='mt-6' />
            </Container>
        </div>
    )
}

export default Features