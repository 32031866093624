import React from 'react';
import Swal from 'sweetalert2';
import swalConfig from '../config/swalConfig';
import { useLogout } from '../hooks/useLogout';
import { use } from 'i18next';
import { useTranslation } from 'react-i18next';

const LogoutAction: React.FC<{ children: any }> = ({ children }) => {

    const { logout } = useLogout();
    const { t } = useTranslation();

    const handleClick = async () => {

        Swal.fire(swalConfig({
            title: t('logout_confirm'),
            icon: 'question',
            confirmButtonText: t('logout'),
            showCancelButton: true,
            cancelButtonText: t('cancel'),
            position: 'top'
        })).then(async (result) => {
            if (result.isConfirmed) {
                await logout();
            }
        });

    }

    return (
        <a className='flex cursor-pointer items-center gap-3 w-full' onClick={handleClick}>
            {children}
        </a>
    )
}

export default LogoutAction