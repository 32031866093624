import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import React, { useEffect, useState } from 'react';
import Body from '../../../components/body/Body';
import DefaultButton from '../../../components/buttons/DefaultButton';
import FormControl from '../../../components/forms/FormControl';
import ModalWrapper from '../../../components/modals/ModalWrapper';
import { SelectOption } from '../../../constants/type-constants';
import { useCommonService } from '../../../hooks/useCommonService';
import { DivisionTableRows } from './division-interfaces';
import DivisionTable from './DivisionTable';
import { useDivisionManagementService } from './useDivisionManagementService';

const DivisionManagement: React.FC = () => {

    const {
        fetchStatusOptions
    } = useCommonService();
    const {
        fetchDivisions,
        saveDivision,
        handleInputChange,
        deleteDivision,
    } = useDivisionManagementService();

    const [statusOptions, setStatusOptions] = useState<SelectOption[]>([]);

    const [divisionId, setDivisionId] = useState('');
    const [divisionName, setDivisionName] = useState('');
    const [status, setStatus] = useState('');

    const [divisionTableRows, setDivisionTableRows] = useState<DivisionTableRows[]>([]);

    const [divisionModalOpen, setDivisionModalOpen] = useState(false);
    const handleDivisionModalOpen = () => setDivisionModalOpen(true);
    const handleDivisionModalClose = () => setDivisionModalOpen(false);

    useEffect(() => {
        fetchDivisions(setDivisionTableRows);
        fetchStatusOptions(setStatusOptions, setStatus);
    }, []);
  
    // EDIT CLICK HANDLER
    const handleTableButtonEditClick = async (id: string, divisionName: string, status: string) => {
        setDivisionId(id.toString());
        setDivisionName(divisionName);
        setStatus(status);
        handleDivisionModalOpen();
    };

    // DELETE CLICK HANDLER
    const handleTableButtonDeleteClick = async (id: string, divisionName: string) => {
        const postData = {
            id: id,
            divisionName: divisionName,
            status: 'Deleted'
        }
        const deleted = await deleteDivision(postData);
        if (deleted) {
            fetchDivisions(setDivisionTableRows);
        }
    };

    const handleAddButtonClick = async () => {
        setDivisionId('');
        setDivisionName('');
        setStatus(statusOptions[0].value);
        handleDivisionModalOpen();
    };

    const handleDivisionSaveClick = async () => {
        const saved = await saveDivision(
            {
                id: divisionId == '' ? null : divisionId,
                divisionName: divisionName,
                status: status
            }
        )
        if (saved) {
            fetchDivisions(setDivisionTableRows);
            handleDivisionModalClose();
        }
    }

    return (
        <Body>
            <div className='flex flex-wrap justify-between items-center'>
                <h3 className='flex justify-center items-center gap-4'><DeviceHubIcon /> Division Management</h3>
            </div>

            <hr />

            <div className='grid grid-cols-1 lg:grid-cols-2 items-end mt-2'>
                <div className='flex w-max'>
                    <h4>Division List</h4>
                </div>
                <div className='flex w-max ml-auto'>
                    <DefaultButton
                        onClick={handleAddButtonClick}
                        buttonText='Add New Division'
                        buttonIcon={AddRoundedIcon}
                    />
                </div>
            </div>

            <DivisionTable
                rows={divisionTableRows}
                handleEditClick={handleTableButtonEditClick}
                handleDeleteClick={handleTableButtonDeleteClick}
            />

            <ModalWrapper
                width='small'
                open={divisionModalOpen}
                handleClose={handleDivisionModalClose}
                title={'Division Details'}>
                <form id='divisionForm' className='flex flex-wrap'>
                    <div className='w-full lg:w-2/3 lg:pr-4'>
                        <input type='text' id='id' name='id' value={divisionId} readOnly hidden />
                        <FormControl
                            label='Division Name'
                            type={'text'}
                            id={'divisionName'}
                            value={divisionName}
                            onChange={handleInputChange(setDivisionName)}
                            mandatoryField={false}
                        />
                    </div>
                    <div className='w-full lg:w-1/3'>
                        <FormControl
                            label='Status'
                            type={'select'}
                            id={'status'}
                            value={status}
                            options={statusOptions}
                            onChange={handleInputChange(setStatus)}
                            mandatoryField={true}
                        />
                    </div>
                </form>
                <div className='mt-4'>
                    <DefaultButton
                        onClick={handleDivisionSaveClick}
                        buttonText={'Save Division Details'}
                    />
                </div>
            </ModalWrapper>
        </Body>
    )
}

export default DivisionManagement