import React from 'react'
import Card from '../../../../components/cards/Card'
import { Container } from '@mui/material'
import { DateRange, LocationOn, Search } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

const HowItWorks: React.FC = () => {

    const { t } = useTranslation();

    const steps = [
        { icon: <LocationOn className='mb-1 mr-2' />, title: t('choose_your_origin_and_destination') },
        { icon: <DateRange className='mb-1 mr-2' />, title: t('select_your_travel_date') },
        { icon: <Search className='mb-1 mr-2' />, title: t('browse_available_trips_and_book_your_tickets') },
    ]

    return (
        <div className='my-6'>
            <Container>
                {/* How It Works */}
                <section>
                    <h2 className="text-sky-700">{t('how_it_works_header')}</h2>
                    <p className='mb-6 text-justify'>
                        {t('how_it_works_sub_header')}
                    </p>
                    <Card>
                        <div className='grid grid-cols-1 lg:grid-cols-3 gap-6'>
                            {
                                steps.map((step, index) => (
                                    <div key={index} className='text-center border border-dashed text-lg border-slate-300 rounded-lg p-4 text-slate-500'>
                                        <div>
                                            {step.icon} {step.title}
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </Card>
                </section>
            </Container>
        </div>
    )
}

export default HowItWorks