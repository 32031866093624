import { Container } from '@mui/material';
import { t } from 'i18next';
import React from 'react'

const AboutUs: React.FC = () => {
    return (
        <div className='my-6'>
            <Container>
                {/* About the Portal */}
                <section>
                    <h2 className="text-sky-700 mb-4">{t('about_the_portal_header')}</h2>
                    <p className="text-slate-600 text-justify">
                        {t('about_the_portal_body')}
                    </p>
                </section>
                <hr className='mt-6' />
            </Container>
        </div>
    )
}

export default AboutUs;