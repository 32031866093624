import React from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import './styles/main.sass'
import { ROUTES } from './constants/routes-constants'
import { ROLES } from './constants/role-constants'
import RequireAuth from './auth/RequireAuth'
// pages
import NotFound from './pages/common/NotFound'
import UnauthorisedAccess from './pages/common/UnauthorisedAccess'
import Login from './pages/auth/Login'
import SignUp from './pages/auth/SignUp'
import OtpVerification from './pages/auth/OtpVerification'
import ForgotPassword from './pages/auth/ForgotPassword'
import PasswordOtpVerification from './pages/auth/PasswordOtpVerification'
import Landing from './pages/unlogged/landing-page/Landing'
import SearchResults from './pages/unlogged/search-results/SearchResults'
import TicketBooking from './pages/user/ticket-booking/TicketBooking'
import BundleTicketBooking from './pages/user/bundle-ticket-booking/BundleTicketBooking'
import SysAdminDashboard from './pages/sysadmin/dashboard/Dashboard'
import DivHeadDashboard from './pages/division-head/Dashboard'
import GhatManagement from './pages/manage/ghat-management/GhatManagement'
import RouteManagement from './pages/manage/route-management/RouteManagement'
import TripManagement from './pages/manage/trip-management/TripManagement'
import VesselManagement from './pages/manage/vessel-management/VesselManagement'
import DivisionManagement from './pages/manage/division-management/DivisionManagement'
import AccountSettings from './pages/user/account-settings/AccountSettings'
import GhatOperator from './pages/ghat-operator/GhatOperator'
import TicketManage from './pages/ghat-operator/ticket-manage/TicketManage'
import RateManagement from './pages/manage/rate-management/RateManagement'
import TripSettings from './pages/manage/trip-settings/TripSettings'
import VesselLayout from './pages/manage/vessel-layout/VesselLayout'
import OrderDetails from './pages/user/payment/OrderDetails'
import BookingTicketDetails from './pages/user/booking-history/ticket-details/BookingTicketDetails'
import UserManagement from './pages/manage/user-management/UserManagement'
import YourBookings from './pages/user/booking-history/ticket-list/YourBookings'

const RootComponent: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path="*" element={<NotFound />} />
                <Route path={ROUTES.AUTH.LOGIN} element={<Login />} />
                <Route path={ROUTES.AUTH.SIGN_UP} element={<SignUp />} />
                <Route path={ROUTES.AUTH.LOGOUT} element={<Login />} />
                <Route path={ROUTES.AUTH.VERIFY_OTP} element={<OtpVerification />} />
                <Route path={ROUTES.AUTH.FORGOT_PASSWORD} element={<ForgotPassword />} />
                <Route path={ROUTES.AUTH.VERIFY_PASSWORD_OTP} element={<PasswordOtpVerification />} />
                <Route path={ROUTES.UNAUTHORISED} element={<UnauthorisedAccess />} />
                <Route path={ROUTES.NOT_FOUND} element={<NotFound />} />

                <Route path={ROUTES.INDEX} element={<Landing />} />

                {/* AUTHENTICATED */}
                <Route element={<RequireAuth allowedRoles={ROLES.ANY_ROLE} />}>
                    <Route path={ROUTES.LOGGED_IN.SETTINGS} element={<AccountSettings />} />
                </Route>

                {/* SYSADMIN */}
                <Route element={<RequireAuth allowedRoles={[ROLES.SYSADMIN]} />}>
                    <Route path={ROUTES.SYSTEM_ADMIN.DASHBOARD} element={<SysAdminDashboard />} />
                    <Route path={ROUTES.MANAGE.DIVISION} element={<DivisionManagement />} />
                    <Route path={ROUTES.MANAGE.GHAT} element={<GhatManagement />} />
                    <Route path={ROUTES.MANAGE.ROUTE} element={<RouteManagement />} />
                    <Route path={ROUTES.MANAGE.TRIP} element={<TripManagement />} />
                    <Route path={ROUTES.MANAGE.TRIP_SETTINGS} element={<TripSettings />} />
                    <Route path={ROUTES.MANAGE.VESSEL} element={<VesselManagement />} />
                    <Route path={ROUTES.MANAGE.VESSEL_LAYOUT} element={<VesselLayout />} />
                    <Route path={ROUTES.MANAGE.RATE} element={<RateManagement />} />
                    <Route path={ROUTES.MANAGE.USERS} element={<UserManagement />} />
                </Route>

                {/* DIVISION_HEAD */}
                <Route element={<RequireAuth allowedRoles={[ROLES.DIVISION_HEAD]} />}>
                    <Route path={ROUTES.DIVISION_HEAD.DASHBOARD} element={<DivHeadDashboard />} />
                    <Route path={ROUTES.MANAGE.GHAT} element={<GhatManagement />} />
                    <Route path={ROUTES.MANAGE.ROUTE} element={<RouteManagement />} />
                    <Route path={ROUTES.MANAGE.TRIP} element={<TripManagement />} />
                    <Route path={ROUTES.MANAGE.VESSEL} element={<VesselManagement />} />
                </Route>

                {/* GHAT OPERATOR */}
                <Route path={ROUTES.GHAT_OPERATOR.DASHBOARD} element={<GhatOperator />} />
                <Route path={ROUTES.GHAT_OPERATOR.TRIP_MANAGE} element={<TicketManage />} />
                {/* <Route element={<RequireAuth allowedRoles={[ROLES.GHAT_IC]} />}>
                </Route> */}

                {/* USER */}
                <Route path={ROUTES.USER.DASHBOARD} element={<Landing />} />
                <Route path={ROUTES.USER.SEARCH_RESULTS} element={<SearchResults />} />

                <Route element={<RequireAuth allowedRoles={[ROLES.USER]} />}>
                    <Route path={ROUTES.USER.TICKET_BOOKING} element={<TicketBooking />} />
                    <Route path={ROUTES.USER.ORDER_DETAILS} element={<OrderDetails />} />
                    <Route path={ROUTES.USER.MY_BOOKINGS} element={<YourBookings />} />
                    <Route path={ROUTES.USER.BOOKING_TICKET_DETAILS} element={<BookingTicketDetails />} />
                    <Route path={ROUTES.USER.BUNDLE_TICKET_BOOK} element={<BundleTicketBooking />} />
                </Route>
            </Routes>

        </Router>
    )
}

export default RootComponent
