import { FileDownloadOutlined } from '@mui/icons-material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import Body from '../../../../components/body/Body';
import PrimaryButton from '../../../../components/buttons/PrimaryButton';
import Card from '../../../../components/cards/Card';
import { BookingTicketTypes, GoodsView, PassengerView, VehicleView } from '../../payment/order-details-interfaces';
import TicketItemDetailsTable from '../../payment/sections/TicketItemDetailsTable';
import { IBookingTicketDetails } from './booking-ticket-details-interfaces';
import PaymentInfo from './sections/PaymentInfo';
import TicketHeader from './sections/TicketHeader';
import TripVesselInfo from './sections/TripVesselInfo';
import useBookingTicketDetailsService from './useBookingTicketDetailsService';
import useTicketGenerationService from './useTicketGenerationService';
import { useTranslation } from 'react-i18next';

const BookingTicketDetails: React.FC = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const bookingTicketDetails = location.state.bookingTicketDetails as IBookingTicketDetails;

    const { generateTicketPdf } = useTicketGenerationService(t);

    const { getTableData } = useBookingTicketDetailsService(t);

    const renderTable = (type: BookingTicketTypes) => {
        const tickets = bookingTicketDetails[type];
        if (!tickets || tickets.length === 0) return null;
        const { title, headers } = getTableData(type);
        return (
            <TicketItemDetailsTable
                title={title}
                headers={headers}
                tickets={tickets}
            />
        )
    }

    const handleDownloadClick = async () => {
        generateTicketPdf(bookingTicketDetails);
    };

    return (
        <Body>
            <h2 className='mb-3'>{t('ticket_details')}</h2>

            <Card>
                <>
                    {/* Header */}
                    <TicketHeader
                        bookingTicketDetails={bookingTicketDetails}
                        button={<PrimaryButton
                            onClick={handleDownloadClick}
                            buttonText={t('ticket_download')}
                            buttonIcon={FileDownloadOutlined}
                        />}
                    />

                    {/* Trip and Vessel Information */}
                    <TripVesselInfo bookingTicketDetails={bookingTicketDetails} />

                    {/* Ticket Tables */}
                    {renderTable('passengers')}
                    {renderTable('vehicles')}
                    {renderTable('goods')}

                    {/* Payment Information */}
                    <PaymentInfo bookingTicketDetails={bookingTicketDetails} />

                    {/* Footer Placeholder */}
                    <div className="text-gray-500 text-sm text-justify mt-10">
                        * {t('ticket_instruction_1')}
                    </div>
                </>
            </Card>
        </Body>
    );
};

export default BookingTicketDetails;
