import React from 'react'
import { BookingTicketTypes, PaymentPost } from './order-details-interfaces'
import { useHandleErrorService } from '../../../hooks/useHandleErrorService';
import { AxiosResponse } from 'axios';
import { useApiService } from '../../../hooks/useApiService';
import API_ENDPOINT from '../../../constants/api-constants';

export default function useOrderDetailsService(t: (key: string) => string) {

    const { handleError } = useHandleErrorService();
    const { postAuthRequest } = useApiService();

    const getTableData = (type: BookingTicketTypes) => {
        const headersMap = {
            passengers: [
                { name: 'passengerName', label: t('name') },
                { name: 'gender', label: t('gender') },
                { name: 'age', label: t('age') },
                { name: 'pwd', label: t('differently_abled') },
                { name: 'ticketNo', label: t('ticket_no') }
            ],
            vehicles: [
                { name: 'regNo', label: t('reg_no') },
                { name: 'model', label: t('model') },
                { name: 'ownerName', label: t('owner_name') },
                { name: 'ticketNo', label: t('ticket_no') }
            ],
            goods: [
                { name: 'description', label: t('description') },
                { name: 'weight', label: t('weight') },
                { name: 'ticketNo', label: t('ticket_no') }
            ]
        };

        const titlesMap = {
            passengers: t('passenger_details'),
            vehicles: t('vehicle_details'),
            goods: t('goods_details')
        };

        return {
            title: titlesMap[type],
            headers: headersMap[type]
        }
    }

    const updatePayment = async (bookingPayment: PaymentPost) => {
        try {
            const response: AxiosResponse = await postAuthRequest(
                API_ENDPOINT.BOOKING.UPDATE_PAYMMENT,
                bookingPayment
            )

            return true;
            
        } catch (error) {
            handleError(error);
        }
    }

    return {
        getTableData,
        updatePayment
    }
}
