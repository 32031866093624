import React from 'react'
import { IndividualTicket } from '../order-details-interfaces';
import { useTranslation } from 'react-i18next';

interface BookingInvoiceProps {
    ticketList: IndividualTicket[];
    grossAmount: number;
    convenienceFee: number;
    netAmount: number;
    submitButton: React.ReactElement
}

const BookingInvoice: React.FC<BookingInvoiceProps> = ({ ...props }) => {
    const { t } = useTranslation();
    return (
        <div>
            <h4>{t('invoice')}(₹)</h4>
            <section className="mb-6 border-b pb-6">
                <table className='w-full'>
                    <thead>
                        <tr>
                            <th className='w-auto text-left font-medium'>{t('ticket_no')}</th>
                            <th className='w-auto text-right font-medium'>{t('price')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.ticketList.map((ticket, index) => (
                            <tr key={index}>
                                <td>{ticket.ticketNo}</td>
                                <td className='text-right'>{ticket.price}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>
            <section className="mb-6 border-b pb-6">
                <div className="flex justify-between mb-2">
                    <span>{t('net_amount')}</span>
                    <span>{props.grossAmount}</span>
                </div>
                <div className="flex justify-between mb-2">
                    <span>{t('convenience_fee')}</span>
                    <span>{props.convenienceFee}</span>
                </div>
                <div className="flex justify-between font-bold">
                    <span>{t('total')}</span>
                    <span>{props.netAmount}</span>
                </div>
            </section>
            <section>
                <div className="mb-6 text-xs text-gray-500 text-justify leading-5 border rounded px-4 py-2 border-red-300">
                    {t('payment_disclaimer')}
                </div>
                {props.submitButton}
            </section>
        </div>
    )
}

export default BookingInvoice;