import { ArrowForwardRounded } from '@mui/icons-material';
import React from 'react'
import { BookingResponse } from '../order-details-interfaces';
import { dateUtil } from '../../../../utility/dateUtil';
import { useTranslation } from 'react-i18next';

interface TripDetailsProps {
    bookingResponse: BookingResponse
}

const TripDetails: React.FC<TripDetailsProps> = ({ bookingResponse }) => {
    const { t } = useTranslation();
    return (
        <>
            <h4 className='mb-4 flex gap-x-4 items-center'>{t('trip')}:
                <span>{bookingResponse.booking.trip.route.ghatFrom.ghatName}</span>
                <span><ArrowForwardRounded /></span>
                <span>{bookingResponse.booking.trip.route.ghatTo.ghatName}</span>
            </h4>
            <section className="grid gap-4 sm:grid-cols-2 mb-6 border-b pb-6 ">
                <div>
                    <h3 className="font-medium text-lg text-gray-600">{t('vessel_information')}</h3>
                    <table>
                        <tbody>
                            <tr>
                                <td className='w-32'>{t('name')}</td>
                                <td className='font-medium'>{bookingResponse.booking.trip.vessel.name}</td>
                            </tr>
                            <tr>
                                <td className='w-32'>{t('registration_no')}</td>
                                <td className='font-medium'>{bookingResponse.booking.trip.vessel.regNo}</td>
                            </tr>
                            <tr>
                                <td className='w-32'>{t('dock_no')}</td>
                                <td className='font-medium'>{bookingResponse.booking.trip.dockNo}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <h3 className="font-medium text-lg text-gray-600">{t('trip_timing')}</h3>
                    <table>
                        <tbody>
                            <tr>
                                <td className='w-40'>{t('date')}</td>
                                <td className='font-medium'>{dateUtil.formatDateTime(bookingResponse.booking.trip.tripDate).split('|')[0].trim()}</td>
                            </tr>
                            <tr>
                                <td className='w-40'>{t('departure_time')}</td>
                                <td className='font-medium'>{bookingResponse.booking.trip.departureTime}</td>
                            </tr>
                            <tr>
                                <td className='w-40'>{t('duration')}</td>
                                <td className='font-medium'>{bookingResponse.booking.trip.tripDuration}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    )
}

export default TripDetails;