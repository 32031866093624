import React, { useState } from 'react'
import API_ENDPOINT from '../../constants/api-constants';
import { useApiService } from '../../hooks/useApiService';
import { useHandleErrorService } from '../../hooks/useHandleErrorService';
import Body from '../../components/body/Body';
import Card from '../../components/cards/Card';
import FormControl from '../../components/forms/FormControl';
import DefaultButton from '../../components/buttons/DefaultButton';
import { ROUTES } from '../../constants/routes-constants';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const OtpVerification: React.FC = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { postPublicRequest } = useApiService();
    const { handleError } = useHandleErrorService();

    const [otp, setOtp] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch (e.target.name) {
            case 'otp':
                if (e.target.value === '' || /^\d+$/.test(e.target.value)) {
                    setOtp(e.target.value);
                }
                break;
            case 'password':
                setPassword(e.target.value);
                break;
            case 'confirmPassword':
                setConfirmPassword(e.target.value);
                break;
            default:
                break;
        }
    }

    const verifyOtp = async () => {
        const postData = {
            fullName: Cookies.get('fullName'),
            email: Cookies.get('email'),
            mobileNo: Cookies.get('mobileNo'),
            password: password,
            confirmPassword: confirmPassword,
            otp: otp
        }
        try {
            const response: any = await postPublicRequest(API_ENDPOINT.AUTH.POST_SIGN_UP, postData, true);
            if (response) {
                const { message } = response.data;
                toast.success(message);
                navigate(ROUTES.AUTH.LOGIN);
            }

        } catch (error: any) {
            handleError(error);
        }
    }

    const resendOtp = async () => {
        const postData = {
            fullName: Cookies.get('fullName'),
            email: Cookies.get('email'),
            mobileNo: Cookies.get('mobileNo')
        }
        try {
            const response: any = await postPublicRequest(API_ENDPOINT.AUTH.POST_RESEND_OTP, postData, true);
            if (response) {
                const { message } = response.data;
                toast.success(message);
            }
        } catch (error) {
            handleError(error);
        }
    }

    return (
        <Body>
            <div className="w-full md:w-7/12 lg:w-5/12 flex justify-center m-auto">
                <Card>
                    <div>
                        <h1 className="text-sky-600 text-xl font-medium text-center leading-tight tracking-tight md:text-2xl">
                            {t('verify_otp')}
                        </h1>
                        <div className="space-y-2 md:space-y-3" id="verifyOtpForm">
                            <FormControl
                                label={t('enter_otp')}
                                type={'text'}
                                id={'otp'}
                                value={otp}
                                onChange={handleInputChange}
                                mandatoryField={true}
                            />
                            <FormControl
                                label={t('password')}
                                type={'password'}
                                id={'password'}
                                value={password}
                                onChange={handleInputChange}
                                mandatoryField={true}
                            />
                            <FormControl
                                label={t('confirm_password')}
                                type={'password'}
                                id={'confirmPassword'}
                                value={confirmPassword}
                                onChange={handleInputChange}
                                mandatoryField={true}
                            />
                            <br />
                        </div>
                        <DefaultButton
                            onClick={verifyOtp}
                            buttonText={t('verify_otp')}
                        />

                        <p className="text-sm font-medium text-gray-500 my-3">
                            {t('havent_received')}
                            <button onClick={resendOtp} className="ml-1 font-semibold text-sky-600 hover:underline">
                                {t('resend_otp')}
                            </button>
                        </p>
                    </div>
                </Card>
            </div>
        </Body>
    )
}

export default OtpVerification