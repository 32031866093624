import React from 'react'
import { IBookingTicketDetails } from '../booking-ticket-details-interfaces';
import { useTranslation } from 'react-i18next';

interface PaymentInfoProps {
    bookingTicketDetails: IBookingTicketDetails
}

const PaymentInfo: React.FC<PaymentInfoProps> = ({ bookingTicketDetails }) => {

    const { t } = useTranslation();

    return (
        <section className="grid grid-cols-1 lg:grid-cols-3 gap-4 border-b pb-6 mb-6">
            <div className="flex">
                <table className='w-full'><tbody className='font-medium'>
                    <tr><td className='pr-8'>{t('email')}:</td><td className='text-right md:text-start'>{bookingTicketDetails.email}</td></tr>
                    <tr><td className='pr-8'>{t('phone')}:</td><td className='text-right md:text-start'>{bookingTicketDetails.mobileNo}</td></tr>
                    <tr><td className='h-7'></td><td></td></tr>
                </tbody></table>
            </div>
            <div className='flex md:justify-center'>
                <table className='w-full'><tbody className='font-medium'>
                    <tr><td>{t('order_id')}:&emsp;</td><td className='text-right md:text-start'>{bookingTicketDetails.payment.orderId}</td></tr>
                    <tr><td>{t('payment_status')}:&emsp;</td><td className='text-right md:text-start'>{bookingTicketDetails.payment.paymentStatus}</td></tr>
                    <tr><td>{t('payment_mode')}:&emsp;</td><td className='text-right md:text-start'>{bookingTicketDetails.payment.paymentMode}</td></tr>
                </tbody></table>
            </div>
            <div className="flex md:justify-end">
                <table className='w-full'><tbody className='font-medium'>
                    <tr><td className='pr-8'>{t('gross_amount')}:</td><td className='text-right text-2xl font-semibold'>{bookingTicketDetails.payment.grossAmount}</td></tr>
                    <tr><td className='pr-8'>{t('convenience_fee')}:</td><td className='text-right text-2xl font-semibold'>{bookingTicketDetails.payment.convenienceFee}</td></tr>
                    <tr><td className='pr-8'>{t('total_fare')}:</td><td className='text-right text-2xl font-semibold'>{bookingTicketDetails.payment.netAmount}</td></tr>
                </tbody></table>
            </div>
        </section>
    )
}

export default PaymentInfo;